import {AXIOS} from './backend-config';

export default {
    getFeedItemList(page, perPage){
        return AXIOS.get('/fullfeed.json', {
            params: {
                page,
                per_page: perPage
            }
        })
    },
    getSingle(id){
        return AXIOS.get(`/feed/item/${id}`);
    },
    createFeedItem(item){
        return AXIOS.post('/feed/item/', item);
    },
    deleteFeedItem(id){
        return AXIOS.delete(`/feed/item/${id}`);
    },
    updateFeedItem(item){
        return AXIOS.put(`/feed/item/${item.id}`, item);
    },
    uploadFile(fileBlob, itemId, prefix){
        const form = new FormData();
        form.append('file', fileBlob);

        return AXIOS({
            url: `/files/${prefix && prefix !== '' ? prefix + '_' : ''}${fileBlob.name}`,
            method: 'POST',
            data: form,
            headers: {
                'Content-Type': `multipart/form-data; boundary=${form._boundary}`,
                'update-image-feed-item': itemId,
            }
        });
    },
    getBackendVersion() {
        return AXIOS.get('application-info/versions')
    }
}
